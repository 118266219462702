<template>
	<div class="case qjc-relative">
		<h2 class="qjc-relative qjc-texta-l">
			<img class="qjc-absolute" src="../../assets/images/case_m_t.png" alt="标题icon">
			米因健康大數據<br>
			報告示例
		</h2>
		<!-- <p class="sub-t qjc-texta-l">点击查看不同人群的报告示例</p> -->
		
		<div class="classify">
			<dl @click="$router.push('/case/case_ai')" class="qjc-bg-fff">
				<dt>
					<img src="../../assets/images/case_m_health.png" alt="AI智慧健康套餐">
				</dt>
				<dd class="qjc-fts-28 qjc-ftw-b">AI智慧健康套餐</dd>
			</dl>
			<dl @click="$router.push('/case/case_cream')" class="qjc-bg-fff">
				<dt>
					<img src="../../assets/images/case_m_health.png" alt="AI白領精英套餐">
				</dt>
				<dd class="qjc-fts-28 qjc-ftw-b">AI白領精英套餐</dd>
			</dl>
			<dl @click="$router.push('/case/case_baijin')" class="qjc-bg-fff">
				<dt>
					<img src="../../assets/images/case_m_health.png" alt="AI尊貴白金套餐">
				</dt>
				<dd class="qjc-fts-28 qjc-ftw-b">AI尊貴白金套餐</dd>
			</dl>
		</div>
		
		<div class="foot qjc-absolute">
			最終解釋權歸上海米因所有
		</div>
	</div>
</template>

<script>
	import { device } from "@/js/utils"
	
	export default{
		name: "caseSm",
		data() {
			return {}
		},
		beforeCreate() {
			if(device() == 'pc'){
				window.location.href = 'https://www.medinbd.com/case/index.html'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.case{
		height: 14.96rem;
		background: url(../../assets/images/case_m_bg.png) no-repeat;
		background-size: 100% 100%;
		color: #42454A;
		padding-top: 1.89rem;
		
		&>h2{
			display: inline-block;
			width: 5.58rem;
			font-size: 0.64rem;
			font-weight: 500;
			line-height: 0.8rem;
			
			img{
				width: 0.39rem;
				height: 0.33rem;
				top: -0.09rem;
				left: -0.15rem;
				transform: translate(-100%,-100%);
			}
		}
		.sub-t{
			width: 5.58rem;
			font-size: 0.24rem;
			line-height: 0.24rem;
			margin: 0.16rem auto 0;
		}
		
		.classify{
			margin-top: 0.63rem;
			
			&>dl{
				width: 5.58rem;
				height: 1.98rem;
				box-shadow: 0 0.06rem 0.2rem 0 rgba(188,195,238,1);
				border-radius: 0.32rem;
				margin: 0 auto 0.24rem;
				
				dt{
					width: 100%;
					height: 1.36rem;
					
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		
		.foot{
			width: 100%;
			left: 0;
			bottom: 0.31rem;
			font-size: 0.2rem;
			line-height: 0.24rem;
		}
	}
</style>
